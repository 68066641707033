import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Meta from '../components/Meta'
import FlexibleFields from '../components/FlexibleFields'

export const query = graphql`
    query($id: String!) {
        wpGame (id: { eq: $id }) {
            title
            seo {
                title
                metaDesc
                focuskw
                metaKeywords
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
            featuredImage {
                node {
                    sourceUrl
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
            }
            gameFields {
                thumbnail {
                    sourceUrl
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
                video {
                    mediaItemUrl
                }
                videoThumbnail {
                    sourceUrl
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
                developer {
                    sourceUrl
                    mediaDetails {
                        width
                        height
                    }
                }
                logo {
                    sourceUrl
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
                legals
            }
            flexibleContent {
                content {
                    __typename
                    ...on WpGame_Flexiblecontent_Content_About {
                        aboutType
                        scaleMomentum {
                            powerUp {
                                copy
                            }
                            powerUpCopy
                            teamUp {
                                copy
                            }
                            teamUpCopy
                        }
                        powerupTeamup {
                            powerupCopy
                            teamupCopy
                        }
                        proprietaryTechnology {
                            copy
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_CareerDetails {
                        backgroundTextLabel
                    }
                    ... on WpGame_Flexiblecontent_Content_CareerForm {
                        title
                    }
                    ... on WpGame_Flexiblecontent_Content_CareerHeader {
                        background {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_CareersArchive {
                        careerArchiveType
                    }
                    ... on WpGame_Flexiblecontent_Content_Column {
                        heading
                        content
                        position
                    }
                    ... on WpGame_Flexiblecontent_Content_Contact {
                        contactType
                        title
                        getInTouchContent {
                            title
                            titleSize
                            hideDiagonalSlice
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            button {
                                label
                                link
                            }
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_CtaText {
                        title
                        content
                        backgroundText {
                            line
                        }
                        flip
                    }
                    ... on WpGame_Flexiblecontent_Content_GameDetails {
                        title
                        headshot {
                            sourceUrl
                        }
                        quote
                        name
                        showLower
                        lowerTitle
                        lowerStatistic
                        lowerLabel
                        lowerImage {
                            sourceUrl
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_GameHeader {
                        foreground {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                        background {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_GameDetails {
                        title
                        headshot {
                            sourceUrl
                        }
                        quote
                        name
                        showLower
                        lowerTitle
                        lowerStatistic
                        lowerLabel
                        lowerImage {
                            sourceUrl
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_GameResults {
                        results {
                            thumbnail {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            content {
                                mediaItemUrl
                                sourceUrl
                            }
                            statistic
                            unit
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_GamesArchive {
                        gamesArchiveType,
                        sliderContent {
                            game {
                                ... on WpGame {
                                    title
                                    slug
                                    platforms {
                                        nodes {
                                            slug
                                            platformFields {
                                                icon {
                                                    sourceUrl
                                                }
                                            }
                                        }
                                    }
                                    gameFields {
                                        thumbnail {
                                            sourceUrl
                                            mediaDetails {
                                                sizes {
                                                    name
                                                    sourceUrl
                                                }
                                            }
                                        }
                                        videoThumbnail {
                                            sourceUrl
                                        }
                                        video {
                                            mediaItemUrl
                                        }
                                        legals
                                    }
                                }
                            }
                            bigText
                            smallText
                            foreground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            midground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                        }
                        featuredContent {
                            game {
                                ... on WpGame {
                                    title
                                    slug
                                    platforms {
                                        nodes {
                                            slug
                                            platformFields {
                                                icon {
                                                    sourceUrl
                                                }
                                            }
                                        }
                                    }
                                    gameFields {
                                        thumbnail {
                                            sourceUrl
                                            mediaDetails {
                                                sizes {
                                                    name
                                                    sourceUrl
                                                }
                                            }
                                        }
                                        videoThumbnail {
                                            sourceUrl
                                        }	
                                        video {
                                            mediaItemUrl
                                        }
                                        logo {
                                            sourceUrl
                                            mediaDetails {
                                                sizes {
                                                    name
                                                    sourceUrl
                                                }
                                            }
                                        }
                                        legals
                                    }
                                }
                            }
                            foreground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            midground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_GlassdoorReviews {
                        reviews {
                            review
                            rating
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_Hero {
                        title
                        backgroundType
                        background {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                        tagline
                        social
                    }
                    ... on WpGame_Flexiblecontent_Content_Locations {
                        title
                    }
                    ... on WpGame_Flexiblecontent_Content_NewsHeader {
                        title
                        author
                        published
                    }
                    ... on WpGame_Flexiblecontent_Content_OurOfferings {
                        offerings {
                            title
                            tagline
                            description
                            powerUp
                            teamUp
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_Partners {
                        partnersType
                    }
                    ... on WpGame_Flexiblecontent_Content_PostArchive {
                        postArchiveType
                        imageRowsContent {
                            post {
                                ... on WpPost {
                                    title
                                    content
                                    slug
                                    postFields {
                                        offSitePost
                                        postUrl
                                        thumbnail {
                                            mediaItemUrl
                                        }
                                    }
                                }
                            }
                        }
                        featuredContent {
                            post {
                                ... on WpPost {
                                    title
                                    slug
                                    postFields {
                                        offSitePost
                                        postUrl
                                        thumbnail {
                                            mediaItemUrl
                                        }
                                    }
                                }
                            }
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            highlights {
                                image {
                                    sourceUrl
                                }
                            }
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_ProprietaryTechnology {
                        technologies {
                            name
                            title
                            tagline
                            copy
                            image {
                                sourceUrl
                            }
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            theme
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_StaticImage {
                        image {
                            sourceUrl
                        }
                        subtitle
                        hasCredit
                        credit
                    }
                    ... on WpGame_Flexiblecontent_Content_Team {
                        teamMembers {
                            headshot {
                                sourceUrl
                            }
                            name
                            jobTitle
                            description
                            socialLinks {
                                linkedin {
                                    url
                                }
                                facebook {
                                    url
                                }
                                twitter {
                                    url
                                }
                                instagram {
                                    url
                                }
                                youtube {
                                    url
                                }
                            }
                        }
                    }
                    ... on WpGame_Flexiblecontent_Content_Testimonials {
                        testimonials {
                            headshot {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            name
                            jobTitle
                            testimonial
                        }
                        theme
                        title
                        description
                    }
                    ... on WpGame_Flexiblecontent_Content_Video {
                        title
                        game {
                            ... on WpGame {
                                title
                                slug
                                platforms {
                                    nodes {
                                        slug
                                        platformFields {
                                            icon {
                                                sourceUrl
                                            }
                                        }
                                    }
                                }
                                gameFields {
                                    thumbnail {
                                        sourceUrl
                                        mediaDetails {
                                            sizes {
                                                name
                                                sourceUrl
                                            }
                                        }
                                    }	
                                    videoThumbnail {
                                        sourceUrl
                                    }
                                    video {
                                        mediaItemUrl
                                    }
                                    legals
                                    links {
                                        amazonAppStore
                                        googlePlay
                                        iosAppStore
                                        macAppStore
                                        microsoftStore
                                        samsungGalaxyStore
                                        steam
                                        tiltingpointLauncherXsollasitebuilder
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ({ data: { wpGame } }) => (
    <Layout>
        <Meta seo={wpGame?.seo} />
        <FlexibleFields data={ wpGame } content={ wpGame?.flexibleContent?.content ?? [] } />
    </Layout>
)
